export const FirebaseConfig = {
	"projectId": "fongwahtransv3",
	"appId": "1:188676689594:web:3e088cc79daa26b5877c83",
	"databaseURL": "https://fongwahtransv3-default-rtdb.asia-southeast1.firebasedatabase.app",
	"storageBucket": "fongwahtransv3.appspot.com",
	"locationId": "asia-east2",
	"apiKey": "AIzaSyBBqz4YcwlIzGLDMVlqKoZo0t5QPowLWuY",
	"authDomain": "fongwahtransv3.firebaseapp.com",
	"messagingSenderId": "188676689594",
	"measurementId": "G-2FQ7CHVP6E"
};